<template>
  <div class="x-content">
    <div v-if="isWeiXin">
      <van-image width="100%" src="https://taofuguanjia-dev.oss-cn-beijing.aliyuncs.com/tfgj/1648541094978.png?Expires=1963901094&OSSAccessKeyId=LTAI4Fz6XftCq4V9oGqBoyiN&Signature=hlEB2qQAGmVnYNfHQ0ucJRit%2FHw%3D"/>
      <div class="x-formDiv">
        <van-form @submit="onSubmit" class="x-form">
          <div class="x-form-item">
            <div class="x-table">* 输入姓名立即享受保障！</div>
<!--            <vnp-input v-model="fromData.carNumber" style="padding: 0 10px;"></vnp-input>-->
            <input type="text" v-model="fromData.name" class="x-inpurt" placeholder="请输入投保人姓名"/>
          </div>
          <van-button round block native-type="submit" class="x-submit"> 点 击 立 即 投 保 </van-button>
        </van-form>
      </div>
    </div>
    <div style="line-height: 100px;font-size: 20px;" v-if="isWeiXin===false">请在微信端打开链接！</div>
  </div>
</template>
<script>
  // import VnpInput from '../components/vant-number-plate/vnp-input';
  export default {
    // components: {
    //   'vnp-input': VnpInput
    // },
    data() {
      return {
        show: false,
        isWeiXin: false,
        fromData: {
          dealerCode: this.$route.params.code,
          // carNumber: '粤A'
        }
      }
    },
    created() {
      let ua = window.navigator.userAgent.toLowerCase();
      if(ua.match(/MicroMessenger/i) === 'micromessenger'){
        //console.log("微信浏览器");
        this.isWeiXin = true;
      } else{
        //console.log("非微信浏览器");
        //this.isWeiXin = false;
        this.isWeiXin = true;
      }
    },
    methods: {
      //提交表单
      onSubmit(e) {
        // if (!this.fromData.carNumber) {
        //   this.$Dialog({ message: "车牌号不能为空！" });
        //   return;
        // }else if(this.fromData.carNumber.length<7){
        //   this.$Dialog({ message: "请输入正确的车牌号！" });
        //   return;
        // }
        if (!this.fromData.name) {
          this.$Dialog({ message: "投保人姓名不能为空！" });
          return;
        }
        this.$api.gaoSuBaoSave(this.fromData).then((res) => {
          //console.log("高速保进行预下单==>>", res);
          //this.fromData.carNumber = '粤A';
          this.fromData.name = '';
          if(res.code===1000){
            window.location.href = 'https://zy.hinsurance.cn/zypt/wx/api/v1/weixin/userinfo/index?weixinName=xdbx-zs&shareType=etc&businessOrgCode=4400030000&salesPartnerCode=440003006';
          }else if(res.code===998){
            this.$Dialog({ message: "code = " + res.code +", 经销商校验不通过, 投保失败！" });
          }else{
            this.$Dialog({ message: "code = " + res.code +", 投保失败, 请联系管理员！" });
          }
        });
      }
    },
    mounted() {
      document.title = "高速保·高速公路驾乘意外险";
    }
  };
</script>
<style lang="less" scoped>
input {
  outline-color: invert;
  outline-style: none;
  outline-width: 0px;
  border: none;
  border-style: none;
  text-shadow: none;
  -webkit-appearance: none;
  -webkit-user-select: text;
  outline-color: transparent;
  box-shadow: none;
}

.x-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  height: 100vh;

  .x-submit {
    height: 44px;
    background: linear-gradient(136deg, #ffb230 0%, #f76b1c 100%);
    font-size: 14px;
    color: #ffffff;
    font-weight: bold;
    border-radius: unset;
  }
}

.x-formDiv{
  position: fixed;
  bottom: 0;
  z-index: 100;
  width: 100%;
  background-color: white;
  border-top: 1px solid #e4e4e4;
}

.x-form {
  padding: 0px 32px;
  padding-bottom: 10px;

  .x-form-item {
    margin-bottom: 10px;

    .x-table {
      display: inline-block;
      height: 34px;
      line-height: 34px;
      font-size: 14px;
      /*color: #8d8d8d;*/
      color: red;
      vertical-align: middle;
      margin-left: 5%;
    }
    .x-table:after {
      content: ".";
      width: 100%;
      display: inline-block;
      overflow: hidden;
      height: 0;
    }

    .x-inpurt {
      padding: 8px 12px;
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid #e4e4e4;
      font-size: 15px;
      font-weight: 400;
      vertical-align: middle;
      width: 80%;
      margin-left: 4%;
    }

    .x-inpurt:focus {
      border: 1px solid #ec6941;
    }
  }
}
</style>
